import * as React from 'react'
import Seo from '../components/seo'
import AttnLogo from '../images/png/attn.png'
import TrueColorsUnited from '../images/png/truecolorsunited.png'
import HeaderLogo from '../images/png/onairwithlove.png'
import HostedByImage from '../images/png/onairwithlove-hostedby.png'

const OnAirWithLove = () => (
  <div className='wrapper'>
    <Seo title='TikTok For Good - On Air With Love' />
    <div className='show on-air-with-love'>
      <div className='show-body'>
        <div className='row'>
          <div className='column'>
            <div className='show-body show-body__header'>
              <img src={HeaderLogo} alt='TikTok for good - on air with love' />
            </div>
            <div className='show-body show-body__question text-white font-semi-bold'>
              Got questions about love?
            </div>
            <div className='show-body show-body__phone-number font-black'>
              1-833-LUV-IS-ON
            </div>
            <div className='show-body show-body__info text-white font-medium'>
              Leave a voicemail with your questions and we may answer them LIVE!
            </div>
          </div>
          <div className='column align-flex-end'>
            <div className='host'>
              <div className='show-body__hosted-by-text'>hosted by</div>
              <div className='show-body__hosted-by-img'>
                <img
                  src={HostedByImage}
                  alt='@tyshonlawrence and @cristiandennis'
                  />
              </div>
            </div>
            <div className='show-body__hashtag font-bold'>
              <a
                href='https://www.tiktok.com/@tyshonlawrence'
                target='_blank'
                rel='noreferrer noopener'
                >
                @tyshonlawrence
              </a>
            </div>
            <br className='line-break' />
            <div className='show-body__hashtag font-bold'>
              <a
                href='https://www.tiktok.com/@cristiandennis'
                target='_blank'
                rel='noreferrer noopener'
                >
                @cristiandennis
              </a>
            </div>
            <div className='show-body__schedule text-white font-bold'>
              <span>WED</span> <span>JUNE 30</span> 4pm PT | 7pm ET
            </div>
            <div className='show-body__tiktok-hashtag font-medium'>
              Only on&nbsp;
              <a
                href='https://www.tiktok.com/@tiktokforgood'
                target='_blank'
                rel='noreferrer noopener'
                className='text-white font-semi-bold'
                >
                @TikTokForGood
              </a>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='column'>
            <div className='show-body__footer'>
              <div className='show-body__footer-sponsor'>
                <span>
                  Powered by&nbsp;
                  <a
                    href='https://www.attn.com'
                    target='_blank'
                    rel='noreferrer noopener'
                    >
                    <img src={AttnLogo} alt='ATTN logo' className='attn-logo' />
                  </a>
                </span>
                <span>
                  Benefiting&nbsp;
                  <a
                    href='https://truecolorsunited.org'
                    target='_blank'
                    rel='noreferrer noopener'
                    className='true-colors-logo'
                    >
                    <img src={TrueColorsUnited} alt='True colors united logo' />
                  </a>
                </span>
              </div>
              <div className='show-body__footer-legalese text-white font-regular'>
                <span className='font-bold'>Terms and Conditions</span>: You
                must be at least 18 years of age to call the number provided. By
                calling the number provided, you expressly consent to your call,
                and any message you leave, being recorded. Furthermore, you
                agree and acknowledge that your call, your message, and any
                recording thereof may be shared publicly and used in connection
                with any audio or audiovisual production. You agree and
                acknowledge that you have no expectation of privacy regarding
                your call. This is a toll-free number for those calling from the
                United States or Canada, but carrier charges may still apply. If
                you are calling from outside of the United States or Canada,
                additional charges may apply
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default OnAirWithLove
